import radio from "../api";

const state = {
  closedShows: [
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    },
    {
      name: "           ",
      slug: ".",
      presenters: [{ name: "           " }],
      tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
    }
  ],
  recordings: [],
  isLoading: false,
  isLoadingRecordings: false,
  recentShow: undefined,
  recentShowSlug: undefined,
  page: 1,
  nextPage: true,
  recordingsPage: 1,
  nextRecordingsPage: true
};

const getters = {
  getClosedShows: state => {
    //return state.all.data;
    if (state.closedShows) {
      return state.closedShows;
      //return state.all.data.filter(show => !show.closed);
    }
  }
};

const actions = {
  loadClosedShows({ commit, state }, { clear }) {
    commit("setLoadingState", true);
    radio
      .getShows(state.page, false, true)
      .then(response => {
        if (clear) {
          commit("clearClosedShows");
        }
        commit("setLoadingState", false);
        commit("setClosedShows", response);
      })
      .catch(error => {
        error;
      });
  },
  loadShowDetail({ commit }, slug) {
    commit("setLoadingState", true);
    radio
      .getShowDetail(slug)
      .then(response => {
        commit("setLoadingState", false);
        commit("setRecentShow", response);
      })
      .catch(error => {
        error;
      });
  },
  loadShowRecordings({ commit, state }, { slug, clear }) {
    commit("setLoadingRecordingsState", true);
    commit("setRecordingsLoadDisabled");
    if (clear) {
      commit("clearRecordings");
    }
    radio
      .getRecordings(slug, state.recordingsPage)
      .then(response => {
        commit("setLoadingRecordingsState", false);
        commit("setRecordings", response);
      })
      .catch(error => {
        error;
      });
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setLoadingRecordingsState(state, bool) {
    state.isLoadingRecordings = bool;
  },

  setClosedShows(state, resultData) {
    state.page++;
    state.closedShows = [...state.closedShows, ...resultData.data];
    state.nextPage = resultData.next;
  },

  setRecentShow(state, resultData) {
    state.recentShow = resultData.data;
  },

  setRecordingsLoadDisabled(state) {
    state.nextRecordingsPage = false;
  },

  clearClosedShows(state) {
    state.page = 1;
    state.closedShows = [];
    state.nextPage = true;
  },

  clearRecordings(state) {
    state.recordingsPage = 1;
    state.recordings = [];
    state.nextRecordingsPage = true;
  },
  setRecordings(state, resultData) {
    state.recordingsPage++;
    state.recordings = [...state.recordings, ...resultData.data];
    state.nextRecordingsPage = resultData.next;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
