import radio from "../api";

function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}

const data = Array.from({ length: 5 }, () => {
  return {
    date: "",
    weekday: null,
    location: null,
    shows: Array.from({ length: getRandomIntInclusive(3, 6) }, () => {
      return {
        start: null,
        location: null,
        title: Array.from(
          { length: getRandomIntInclusive(10, 60) },
          () => " "
        ).join(" "),
        slug: ".",
        presenters: [],
        external_event: false
      };
    })
  };
});

// state.program.data -> HP upcoming

const state = {
  isLoading: false,
  program: {
    data: data
  },
  programIsLoading: false,
  all: {
    data: {
      title: "Home",
      banners: [
        {
          title: "             ",
          image: "",
          url: "",
          open_in_new_window: true,
          image_small: ""
        },
        {
          title: "                ",
          image: "",
          url: "",
          open_in_new_window: true,
          image_small: ""
        },
        {
          title: "                ",
          image: "",
          url: "",
          open_in_new_window: true,
          image_small: ""
        }
      ],
      latest: [
        {
          name: "           ",
          slug: ".",
          presenters: [{ name: "           " }],
          tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
        },
        {
          name: "           ",
          slug: ".",
          presenters: [{ name: "           " }],
          tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
        },
        {
          name: "           ",
          slug: ".",
          presenters: [{ name: "           " }],
          tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
        },
        {
          name: "           ",
          slug: ".",
          presenters: [{ name: "           " }],
          tags: [{ tag: "   " }, { tag: "   " }, { tag: "   " }]
        }
      ]
    }
  }
};

const getters = {
  getUpcoming: state => {
    //return state.all.data;
    if (state.program.data) {
      return state.program.data;
      //return state.all.data.filter(show => !show.closed);
    }
  },
  getLatestContent: state => {
    //return state.all.data;
    if (state.all.data.latest) {
      return state.all.data.latest;
      //return state.all.data.filter(show => !show.closed);
    }
  }
};

const actions = {
  loadHomePageContent({ commit, state }) {
    if (!state.all.data.shows) {
      commit("setLoadingState", true);
      radio
        .getHomePageContent()
        .then(response => {
          commit("setLoadingState", false);
          commit("setHomePageContent", response);
        })
        .catch(error => {
          error;
        });
    }
  },
  loadHomePageProgram({ commit }, days) {
    commit("setProgramLoadingState", true);
    radio
      .getProgram({ days: days })
      .then(response => {
        commit("setProgramLoadingState", false);
        commit("setHomePageProgram", response);
      })
      .catch(error => {
        error;
      });
  }
};

const mutations = {
  setLoadingState(state, bool) {
    state.isLoading = bool;
  },

  setProgramLoadingState(state, bool) {
    state.programIsLoading = bool;
  },

  setHomePageContent(state, resultData) {
    state.all = resultData;
  },

  setHomePageProgram(state, resultData) {
    state.program = resultData;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
