<template>
  <div class="audio-player">
    <StreamPlayer ref="streamPlayer" />
    <ArchivePlayer v-show="isActiveArchivePlayer()" ref="archivePlayer" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import StreamPlayer from "@/components/AudioPlayer/StreamPlayer.vue";
import ArchivePlayer from "@/components/AudioPlayer/ArchivePlayer.vue";
import { formatDuration, isMobile } from "@/helpers.js";

export default {
  name: "AudioPlayer",
  components: {
    ArchivePlayer,
    StreamPlayer
  },
  computed: {
    ...mapState({
      archivePlayer: state => state.archivePlayer,
      streamPlayer: state => state.streamPlayer
    })
  },
  data: function() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    isMobile,
    formatDuration,
    ...mapMutations("docTitle", ["setPlayingTitle"]),
    ...mapMutations("audioPlayer", ["setPlayingSrc"]),
    ...mapMutations("archivePlayer", [
      "setLoadingState",
      "setLoadedState",
      "setSeekingState",
      "setPlayingState"
    ]),
    ...mapActions("archivePlayer", [
      "updatePlayerState",
      "updateQueueItems",
      "removeQueueItem",
      "setPlayerActiveState",
      "updateWaveform"
    ]),
    ...mapGetters("archivePlayer", ["isActiveArchivePlayer"]),
    ...mapGetters("archivePlayer", ["getFirstQueueItem"])
  }
};
</script>

<style lang="scss">
@import "../../variables";
@import "../../mixins";
.audio-player {
  width: 100%;
}
</style>
