import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //    {
    //      path: "*",
    //      name: "404",
    //      // route level code-splitting
    //      // this generates a separate chunk (404.[hash].js) for this route
    //      // which is lazy-loaded when the route is visited.
    //      component: () =>
    //        import(/* webpackChunkName: "404" */ "./views/NotFound.vue")
    //    },
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/events/:slug",
      name: "EventDetail",
      component: () =>
        import(/* webpackChunkName: "eventdetail" */ "./views/EventDetail.vue")
    },
    {
      path: "/events",
      name: "Events",
      component: () =>
        import(/* webpackChunkName: "shows" */ "./views/Events.vue")
    },
    {
      path: "/archive/:recording",
      alias: "/shows/:slug/:recording",
      name: "ArchiveDetail",
      component: () =>
        import(/* webpackChunkName: "archivedetail" */ "./views/ArchiveDetail.vue")
    },
    {
      path: "/shows/:slug",
      name: "ShowDetail",
      component: () =>
        import(/* webpackChunkName: "showdetail" */ "./views/ShowDetail.vue")
    },
    {
      path: "/shows",
      name: "Shows",
      component: () =>
        import(/* webpackChunkName: "shows" */ "./views/Shows.vue")
    },
    {
      path: "/archive",
      name: "Archive",
      component: () =>
        import(/* webpackChunkName: "archive" */ "./views/Archive.vue")
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: () =>
        import(/* webpackChunkName: "calendar" */ "./views/Calendar.vue")
    },
    {
      path: "/artists/:artist",
      name: "ArtistArchive",
      component: () =>
        import(/* webpackChunkName: "artists" */ "./views/Artists.vue")
    },
    {
      path: "/genres/:genre",
      name: "GenreArchive",
      component: () =>
        import(/* webpackChunkName: "genres" */ "./views/Genres.vue")
    },
    {
      path: "/search/:query",
      name: "SearchResults",
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/Search.vue")
    },
    {
      path: "/search/",
      name: "Search",
      component: () =>
        import(/* webpackChunkName: "search" */ "./views/Search.vue")
    },
    {
      path: "/about/",
      name: "About",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/playlist/:uuid",
      name: "SharedPlaylist",
      component: () =>
        import(/* webpackChunkName: "playlist" */ "./views/Selection.vue")
    },
    {
      path: "/playlist/",
      name: "Playlist",
      component: () =>
        import(/* webpackChunkName: "playlist" */ "./views/Selection.vue")
    },
    {
      path: "/donate/",
      name: "Donate",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Donate.vue")
    }
  ]
});
