<template>
  <div
    class="navigation-search"
    v-bind:class="isSearchIsOpened() ? 'opened' : ''"
  >
    <label class="navigation-link-accented" title="Search" for="querysource">
      <SearchInput />
      <div v-on:click="toggleSearch" class="navigation-icon">
        <span class="navigation-icon-closed icon-magnifier"></span>
        <span class="navigation-icon-opened icon-close"></span>
      </div>
    </label>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import SearchInput from "./SearchInput.vue";

export default {
  name: "SearchNavigation",
  components: {
    SearchInput
  },
  data: function() {
    return {
      opened: false
    };
  },
  computed: {
    ...mapState({
      navigation: state => state.navigation
    })
  },
  mounted: function() {
    this.$root.$on("search_input_closed", () => {
      this.opened = false;
    });
  },
  methods: {
    isSearchIsOpened: function() {
      //console.log('isSearchIsOpened', event, this.getSearchIsOpened());
      // event.preventDefault();
      const opened = this.getSearchIsOpened();
      if (opened) {
        this.$root.$emit("search_input_opened");
      }
      return opened;
    },
    toggleSearch: function() {
      if (this.opened) {
        this.closeSearch();
        this.opened = false;
      } else {
        this.openSearch();
        this.opened = true;
      }
    },
    ...mapActions("navigation", ["openSearch", "closeSearch"]),
    ...mapGetters("navigation", ["getSearchIsOpened"])
  }
};
</script>

<style lang="scss">
@import "../../variables";
.navigation-search {
  position: absolute;
  right: $spacing-default / 2;
  height: $navigation-height;
  //border-left: 1px solid black;
  padding-top: 18px;

  @media (max-width: $screen-md-max) {
    .icon-magnifier {
      display: none;
    }
  }

  @media (min-width: $screen-xxl-min) {
    right: $spacing-default;
  }

  label {
    display: flex;
    height: $search-input-height;
    padding-left: $spacing-default;
    align-items: center;
    color: $color-secondary;
    background: none;
    border-radius: $search-input-height;
    background: none;
    transition: background 0.35s;

    @media (min-width: $screen-xxl-min) {
      padding-left: $spacing-default;
    }

    .navigation-icon {
      display: inline-flex;
      height: $search-input-height;
      padding: 0 $base-unit * 3;
      text-align: right;
      font-size: 32px;
      text-align: center;

      .navigation-icon-opened,
      .navigation-icon-closed {
        display: flex;
        align-self: center;
      }

      .navigation-icon-opened {
        display: none;
        padding: 0 $base-unit;
        font-size: 21px;
      }
    }

    input {
      width: 0;
      padding: 0;
      border: none;
      outline: none;
      color: #000;
      font-size: $search-input-font-size;
      background: none;
      appearance: none;
      transition: width 0.1s;
    }

    .search-input {
      margin-left: 0;
      background: transparent;
    }
  }

  &.opened {
    border-left: none;

    label {
      background: $search-input-bg;
      border-radius: $search-input-height;
    }

    input {
      width: 760px;
      background: none;
      display: block;
      color: $search-input-color;

      &::placeholder {
        color: $footer-lower-anchor-color;
      }
    }

    .search-input {
      background: transparent;
    }

    .navigation-icon {
      color: #000;
      border-left: none;

      .navigation-icon-opened {
        display: flex;
      }

      .navigation-icon-closed {
        display: none;
      }
    }
  }
}
</style>
