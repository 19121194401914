<template>
  <AudioPlayerBase
    class="archive-player"
    :class="getIsHiddenPlayer()"
    ref="archivePlayer"
  >
    <template v-slot:audio-player-control>
      <audio ref="archivesource" id="archivesource"></audio>
      <div
        class="audio-player-control"
        v-bind:class="archivePlayer.playerState"
        v-on:click="playPause"
      >
        <span></span>
      </div>
    </template>
    <template v-slot:audio-player-title>
      <router-link
        :to="{
          name: 'ArchiveDetail',
          params: {
            slug: archivePlayer.item.show.slug,
            recording: archivePlayer.item.recording_name
          }
        }"
        class="audio-player-metadata-title"
      >
        {{ getTitle(archivePlayer.item) }}
      </router-link>
    </template>
    <template v-slot:audio-player-source>
      <span class="time time-elapsed">{{ elapsed }}</span>
      <input
        ref="slider"
        class="audio-slider"
        type="range"
        min="0"
        :max="archivePlayer.item.audio_duration"
        step="1"
        v-on:change="seekItem"
        v-on:input="updateSlider"
      />
      <span class="time time-remaining">{{ remaining }}</span>
    </template>
    <template v-slot:audio-player-addon>
      <VolumeControl
        :audiosource="getAudioSource()"
        :sourceid="'archivesource'"
      />
      <ToggleControl :streamplayer="false" />
    </template>
  </AudioPlayerBase>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AudioPlayerBase from "@/components/AudioPlayer/AudioPlayerBase.vue";
import ToggleControl from "@/components/AudioPlayer/ToggleControl.vue";
import VolumeControl from "@/components/AudioPlayer/VolumeControl.vue";
import Hls from "hls.js";
import { formatDuration, isMobile, localStorageWrite } from "@/helpers.js";

export default {
  name: "ArchivePlayer",
  components: {
    AudioPlayerBase,
    ToggleControl,
    VolumeControl
  },
  computed: {
    ...mapState({
      archivePlayer: state => state.archivePlayer
    })
  },
  data: function() {
    return {
      elapsed: "0:00:00",
      remaining: "0:00:00",
      drag: false,
      hls: null,
      hidden: this.hidden,
      volumeIcon: "icon-volume-medium"
    };
  },
  created() {},
  mounted() {
    var player = this;
    const archiveSource = player.$refs.archivesource;
    this.$root.$on("audioplayer_src_changed", src => {
      if (this.archivePlayer.item.hls_playlist !== src) {
        archiveSource.pause();
        player.updatePlayerState("");
      }
    });
    this.$root.$on("archiveitem_userseek", this.userSeek);
    this.$root.$on("video_playing", () => {
      archiveSource.pause();
      player.updatePlayerState("");
    });

    this.$root.$on("show_stream_player", () => {
      const aplayer = player.$refs.archivePlayer;
      console.log("archive Refs is", player.$refs);
      if (aplayer) {
        aplayer.$el.classList.add("hidden-player");
      }
    });

    this.$root.$on("show_archive_player", () => {
      const aplayer = player.$refs.archivePlayer;
      console.log("archive Refs is", player.$refs);
      if (aplayer) {
        aplayer.$el.classList.remove("hidden-player");
      }
    });

    archiveSource.onpause = function() {
      //console.log("AUDIO EVENT: pause");
      player.updatePlayerState("");
      player.$root.$emit("archiveplayer_pause");
    };

    archiveSource.onplay = function(e) {
      //console.log("AUDIO EVENT: archiveSource.onplay", e.type);
      //const currentTime = localStorage.archivePlayerTime;
      //archiveSource.currentTime = currentTime;
      player.updatePlayerState(e.type);
      player.$root.$emit("archiveplayer_play");
    };

    archiveSource.onplaying = function(e) {
      //console.log("AUDIO EVENT: ", e, thisStore);
      player.updatePlayerState(e.type);
      player.$root.$emit("archiveplayer_play");
    };

    // archiveSource.onprogress = function (e) {
    //     console.log("AUDIO EVENT: ", e)
    // };

    archiveSource.onratechange = function() {
      //console.log("AUDIO EVENT: ", e);
    };

    archiveSource.onseeked = function() {
      //console.log("AUDIO EVENT: ", e.type, archiveSource.paused);
      if (archiveSource.paused) {
        //player.play();
        player.updatePlayerState("");
      } else {
        player.updatePlayerState("playing");
      }
    };

    archiveSource.onseeking = function() {
      //console.log("AUDIO EVENT: ", e.type, archiveSource.paused);
      player.updatePlayerState("waiting");
    };

    archiveSource.onstalled = function() {
      //console.log("AUDIO EVENT: ", e);
      //archiveSource.load();
    };

    archiveSource.onsuspend = function() {
      //console.log("AUDIO EVENT: ", e);
    };

    // archiveSource.ontimeupdate = function (e) {
    //     console.log("AUDIO EVENT: ", e)
    // };

    archiveSource.onvolumechange = function() {
      //console.log("AUDIO EVENT: ", e);
    };

    archiveSource.onwaiting = function(e) {
      //console.log("AUDIO EVENT: ", e, archiveSource.paused);
      player.updatePlayerState(e.type);
    };

    archiveSource.ontimeupdate = function() {
      //console.log("AUDIO EVENT: ", e.type);
      player.$root.$emit("archiveplayer_updatedtime", this.currentTime);
      localStorage.archivePlayerTime = this.currentTime;
      player.updateTime();
    };

    archiveSource.onended = async function() {
      //console.log( "AUDIO EVENT: ", e.type, "autoplay", player.archivePlayer.autoplay);
      if (
        player.archivePlayer.autoplay &&
        player.archivePlayer.queue.length > 0
      ) {
        // play next item or stop if no more items
        const item = player.getFirstQueueItem();
        player.setPlayingSrc({
          src: item.hls_playlist,
          position: item.position
        });
        player.setPlayingTitle(player.getTitle(item));
        player.setMediaMetadata(item);
        player.removeQueueItem();
        localStorage.archivePlayerItem = item;
        await player.loadItem({ item: item });
      } else {
        player.setPlayingState({ bool: false });
      }
    };
  },
  methods: {
    isMobile,
    formatDuration,
    getAudioSource: function() {
      return this.$refs.archivesource;
    },
    getIsHiddenPlayer: function() {
      if (
        !this.$route.name ||
        !this.$route.params ||
        !this.archivePlayer.item.recording_name
      ) {
        return "hidden-player";
      }
      // if (
      //   this.$route.name == "ArchiveDetail" &&
      //   this.$route.params.recording == this.archivePlayer.item.recording_name
      // ) {
      //   return "hidden-player";
      // }
      return "";
    },
    toggleMute: function() {
      if (this.$refs.archivesource.muted) {
        this.$refs.archivesource.muted = false;
        this.volumeIcon = "icon-volume-medium";
      } else {
        this.$refs.archivesource.muted = true;
        this.volumeIcon = "icon-volume-mute2";
      }
    },
    updateSlider: function() {
      this.drag = true;
      this.elapsed = this.formatDuration(
        this.$refs.archivesource ? this.$refs.slider.valueAsNumber : 0
      );
      this.remaining = this.formatDuration(
        this.$refs.archivesource
          ? this.archivePlayer.item.audio_duration -
              this.$refs.slider.valueAsNumber
          : 0
      );
    },
    initTime: function(currentTime) {
      this.elapsed = this.formatDuration(currentTime);
      var remaining = this.archivePlayer.item.audio_duration - currentTime;
      this.remaining = this.formatDuration(remaining < 0 ? 0 : remaining);
      this.$refs.slider.value = currentTime;
    },
    updateTime: function() {
      if (!this.drag) {
        this.elapsed = this.formatDuration(
          this.$refs.archivesource ? this.$refs.archivesource.currentTime : 0
        );
        var remaining = this.$refs.archivesource
          ? this.archivePlayer.item.audio_duration -
            this.$refs.archivesource.currentTime
          : 0;
        this.remaining = this.formatDuration(remaining < 0 ? 0 : remaining);
        this.$refs.slider.value = this.$refs.archivesource
          ? this.$refs.archivesource.currentTime
          : 0;
      }
    },
    userSeek: async function(item, currentTime) {
      if (
        this.archivePlayer.item.hls_playlist !== item.hls_playlist ||
        this.archivePlayer.item.position !== item.position
      ) {
        await this.loadItem({ item: item, seekTo: currentTime });
      }
      this.$refs.archivesource.currentTime = currentTime;
    },
    loadItem: function({ item, play = true, seekTo = null }) {
      const player = this;
      const archiveSource = player.$refs.archivesource;
      player.$refs.slider.value = 0;
      if (play) {
        localStorage.archivePlayerTime = 0;
      }
      player.setLoadingState({ item: item, bool: true });
      player.updateWaveform(item.waveform_data);

      if (Hls.isSupported()) {
        //console.log('ArchivePlayer using external hls');
        if (player.hls) {
          player.hls.destroy();
        }
        player.hls = new Hls();
        player.hls.on(Hls.Events.MANIFEST_PARSED, function() {
          player.setLoadedState({ item: item, bool: true });
          if (play) {
            player.playPause();
          }
          //player.setPlayingState({ bool: true });
        });
        player.hls.loadSource(item.hls_playlist);
        player.hls.attachMedia(archiveSource);
      } else if (archiveSource.canPlayType("application/vnd.apple.mpegurl")) {
        //console.log('ArchivePlayer using native hls');
        archiveSource.src = item.hls_playlist;
        archiveSource.addEventListener("loadeddata", function() {
          player.setLoadedState({ item: item, bool: true });
          //console.log('ArchivePlayer loadeddata', player.archivePlayer.playing);
        });
        if (play) {
          player.playPause();
        }
      } else {
        this.$toasted.error("Your browser cannot play hls.");
      }
      player.setPlayerActiveState(true);
      if (seekTo) {
        //console.log("loadItem", seekTo, archiveSource.currentTime);
        archiveSource.currentTime = seekTo;
        archiveSource.oncanplay = function() {
          archiveSource.currentTime = seekTo;
          archiveSource.oncanplay = null;
        };
      }
      player.$root.$emit("play_content_changed");
    },
    setMediaMetadata: function(item) {
      const player = this;
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: this.getTitle(item),
          artwork: [
            {
              src: item.artwork ? item.artwork : item.image
            }
          ]
        });
        navigator.mediaSession.setActionHandler("play", () => {
          player.playPause();
        });
        navigator.mediaSession.setActionHandler("pause", () => {
          player.playPause();
        });
        navigator.mediaSession.setActionHandler("seekbackward", () => {
          player.seekItemTo(-10);
        });
        navigator.mediaSession.setActionHandler("seekforward", () => {
          player.seekItemTo(10);
        });
      }
    },
    seekItemTo: function(amount) {
      this.$refs.archivesource.currentTime += amount;
    },
    seekItem: function() {
      this.drag = false;
      this.$refs.archivesource.currentTime = event.srcElement.valueAsNumber;
    },
    playPause: function() {
      const archiveSource = this.$refs.archivesource;
      if (archiveSource.paused) {
        this.play();
      } else {
        this.pause();
      }

      if (this.$ga) {
        this.$ga.event({
          eventCategory: "userAction",
          eventAction: "playPause"
        });
      }

      // Prevent Default Action
      return false;
    },
    play: function() {
      const archiveSource = this.$refs.archivesource;
      archiveSource.volume = localStorage.getItem(`${archiveSource.id}_volume`);
      const player = this;
      this.setPlayerActiveState(true);
      player.setPlayingSrc({
        src: player.archivePlayer.item.hls_playlist,
        position: player.archivePlayer.item.position
      });
      player.setPlayingTitle(player.getTitle(player.archivePlayer.item));
      player.setMediaMetadata(player.archivePlayer.item);
      localStorageWrite("archivePlayerItem", player.archivePlayer.item);
      archiveSource.play().then(
        () => {
          console.log("Success play"); // Success!
        },
        reason => {
          console.error("Failed play", reason); // Error!
        }
      );
    },
    pause: function() {
      const archiveSource = this.$refs.archivesource;
      archiveSource.pause();
      //console.log('ArchivePlayer pause', this.archivePlayer.playing);
      //this.setPlayingState({ bool: false });
    },
    formatDate: function(date) {
      return new Date(date).toLocaleDateString("en-US", {
        month: "2-digit",
        year: "2-digit"
      });
    },
    formatShow: function(item) {
      if (item && item.closed_show) {
        return item.title;
      }
      if (item && ["guestmixes", "specials"].includes(item.slug)) {
        return item.title;
      }
      if (item && !item.closed_show) {
        return item.show;
      }
    },
    formatArtists: function(item) {
      if (item) {
        return (
          "by " +
          item.artists
            .map(function(elem) {
              return elem.name;
            })
            .join(" & ")
        );
      }
    },
    getTitle: function(item) {
      const title = [
        this.formatShow(item),
        this.formatDate(item.record_date),
        this.formatArtists(item)
      ];
      return title.join(" ");
    },
    closePlayer: function() {
      this.setPlayerActiveState(false);
      this.pause();
      this.$root.$emit("archiveplayer_closed");
      localStorageWrite("archivePlayerItem", null);
      localStorage.archivePlayerTime = 0;
    },
    ...mapMutations("docTitle", ["setPlayingTitle"]),
    ...mapMutations("audioPlayer", ["setPlayingSrc"]),
    ...mapMutations("archivePlayer", [
      "setLoadingState",
      "setLoadedState",
      "setSeekingState",
      "setPlayingState"
    ]),
    ...mapActions("archivePlayer", [
      "updatePlayerState",
      "updateQueueItems",
      "removeQueueItem",
      "setPlayerActiveState",
      "updateWaveform"
    ]),
    ...mapGetters("archivePlayer", ["getFirstQueueItem"])
  }
};
</script>

<style lang="scss">
@import "../../variables";
@import "../../mixins";

$slider-width-number: 520;
$slider-width: #{$slider-width-number}px;
$slider-height: 2px;
$background-slider: #000;
$background-filled-slider: #fff;
$thumb-width: 18px;
$thumb-height: 18px;
$thumb-radius: 18px;
$thumb-background: #fff;
$thumb-border: 1px solid #777;
$shadow-size: -8px;
$fit-thumb-in-slider: -8px;

$volume-width-number: 80;
$volume-width: #{$volume-width-number}px;
$volume-height: 2px;

@function makelongshadow($color, $size) {
  $val: 5px 0 0 $size $color;

  @for $i from 6 through $slider-width-number {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }

  @return $val;
}

audio {
  height: 0;
  width: 0;
  display: none;
}

.archive-player {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: $navigation-height-xs;
  border: 2px solid $navigation-bg;
  width: 100%;
  color: $navigation-color-alternative;
  //box-shadow: -15px 0px 15px 15px rgba(0, 0, 0, 0.15);
  background: $navigation-bg;
  z-index: 19;
  transition: height 0.5s, opacity 0.5s; /* Smooth transition */
  opacity: 1;
  //opacity: 0.95;
  // @include blurred-bg($navigation-bg-alternative);

  &.hidden-player {
    z-index: 20;
    opacity: 0;
    height: 0;
    div,
    span,
    button,
    .audio-player-control,
    .audio-player-control::before {
      height: 0 !important;
      visibility: hidden;
    }
  }

  .audio-player-metadata-title {
    color: $navigation-color-alternative;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .audio-player-metadata {
    flex-direction: column;
    margin: 0 5px;

    @media (min-width: $screen-md-min) {
      align-items: center;
      flex-direction: row;
    }

    .audio-player-metadata-title {
      order: 1;
      max-width: 100%;
    }
  }

  .audio-player-metadata-source {
    order: 2;
    display: flex;
    flex-shrink: 1;
    align-items: center;

    @media (min-width: $screen-md-min) {
      justify-content: flex-end;
    }

    .time {
      display: block;
      padding: 0;
      line-height: 16px;
      font-family: monospace;
      font-size: 11px;

      @media (min-width: $screen-md-min) {
        font-size: 13px;
        padding: 0 $spacing-default 0 $base-unit;

        &:first-child {
          padding: 0 $base-unit 0 $spacing-default;
        }
      }
    }

    .time-elapsed {
      padding-right: 5px;
    }

    .time-remaining {
      padding-left: 5px;
    }

    input.audio-slider {
      align-items: center;
      appearance: none;
      background: none;
      cursor: pointer;
      display: flex;
      height: 100%;
      min-height: 16px;
      overflow: hidden;
      width: $slider-width;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:active {
        outline: none;
      }

      &::-webkit-slider-runnable-track {
        background: $background-filled-slider;
        content: "";
        height: $slider-height;
      }

      &::-webkit-slider-thumb {
        @include size($thumb-width, $thumb-height);

        appearance: none;
        background: $thumb-background;
        border-radius: $thumb-radius;
        box-shadow: makelongshadow($background-slider, $shadow-size);
        margin-top: $fit-thumb-in-slider;
        border: $thumb-border;
      }

      &::-moz-range-track {
        background: $background-slider;
        width: $slider-width;
        height: $slider-height;
      }

      &::-moz-range-thumb {
        @include size($thumb-width, $thumb-height);

        background: $thumb-background;
        border-radius: $thumb-radius;
        border: $thumb-border;
        position: relative;
      }

      &::-moz-range-progress {
        height: $slider-height;
        background: $background-filled-slider;
        border: 0;
        margin-top: 0;
      }

      &::-ms-track {
        background: transparent;
        border: 0;
        border-color: transparent;
        border-radius: 0;
        border-width: 0;
        color: transparent;
        height: $slider-height;
        margin-top: 10px;
        width: $slider-width;
      }

      &::-ms-thumb {
        @include size($thumb-width, $thumb-height);

        background: $thumb-background;
        border-radius: $thumb-radius;
        border: $thumb-border;
      }

      &::-ms-fill-lower {
        background: $background-filled-slider;
        border-radius: 0;
      }

      &::-ms-fill-upper {
        background: $background-slider;
        border-radius: 0;
      }

      &::-ms-tooltip {
        display: none;
      }
    }
  }

  .show-playlist {
    display: none;
    padding: $base-unit;
    color: $navigation-color-alternative;
    @include button-reset;

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }
}
</style>
