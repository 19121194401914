<template id="clausules-template">
  <div class="clausules">
    <!-- span>Privacy Policy</span -->
    <!-- span>Terms of Use</span -->
    <a @click="displayCookiesPreferences = true">Cookie Preferences</a>
    <pre id="privacy-policy" ref="privacy">
          TODO: Add Privacy Policy content
      </pre
    >
    <pre id="terms-of-use" ref="terms">
          TODO: Add Terms of Use content
      </pre
    >
    <pre id="cookie-policy" ref="cookies">
A cookie is a short text file that allows a website to record information about your visit.

Cookies are used on radiopunctum.cz for traffic analysis and efficient operation on the website.

The obligation to inform about cookies is governed by Act No. 89 of Act No. 127/2005 Coll., On Electronic Communications.
      </pre
    >
    <div v-if="displayCookiesPreferences" id="cookie-preferences">
      <div>
        We use cookies to improve user experience, and analyze website traffic.
        By clicking “Accept”, you consent to store on your device all the
        technologies described in our
        <strong @click="showCookiePolicy">Cookie Policy</strong>. You can change
        your cookie settings at any time by clicking “Cookie Preferences.”
      </div>
      <span class="buttons">
        <button @click="allowTracking">Accept</button
        ><button @click="declineTracking">Decline</button>
      </span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import cookie from "vue-cookie";

export default {
    name: "clausules",
    template: "#clausules-template",
    data: function() {
        return {
            displayCookiesPreferences: false
        }
    },
    mounted: function() {
        let allowTracking = cookie.get('allow-tracking', null)
        if (allowTracking == null) {
          this.displayCookiesPreferences = true;
        } else if (allowTracking) {
            if(this.$ga) {
                this.$ga.enable();
            }
        }
    },
    methods: {
        getPreContainer: function() {
            let pre = document.createElement('pre');
            pre.style.maxHeight = "400px";
            pre.style.margin = "0";
            pre.style.padding = "24px";
            pre.style.whiteSpace = "pre-line";
            pre.style.textAlign = "justify";
            return pre
        },
        showPrivacyPolicy: function() {
           let pre = this.getPreContainer();
           pre.appendChild(document.createTextNode(this.$refs.privacy.textContent));
           this.$toasted.show(pre);
        },
        showTermsOfUse: function() {
           let pre = this.getPreContainer();
           pre.appendChild(document.createTextNode(this.$refs.terms.textContent));
           this.$toasted.show(pre);
        },
        showCookiePolicy: function() {
           let pre = this.getPreContainer();
           pre.appendChild(document.createTextNode(this.$refs.cookies.textContent));
           this.$toasted.show(pre);
        },
        allowTracking: function() {
          cookie.set('allow-tracking', true, { expires: "10Y" });
          this.displayCookiesPreferences = false;
          if(this.$ga) {
            this.$ga.enable();
          }
        },
        declineTracking: function() {
          cookie.set('allow-tracking', false, { expires: "10Y" });
          this.displayCookiesPreferences = false;
          if(this.$ga) {
            this.$ga.disable();
          }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../variables";

a, span {
  margin-right: $spacing-default;
  text-decoration: underline;
  color: $footer-lower-anchor-color;
  white-space: nowrap;
}

a {
  cursor: pointer;
}

#privacy-policy, #terms-of-use, #cookie-policy {
    display: none;
}


.clausules {
  position: relative;
}

#cookie-preferences {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0px;
  bottom: 80px;
  background: black;
  padding: $base-unit * 5;


  > div {
    padding-bottom: $spacing-default;

    @media (min-width: $screen-sm-min) {
      padding-right: $spacing-default;
      padding-bottom: 0;
    }
  }

  strong {
    cursor: pointer;
  }

  .buttons {
      white-space: nowrap;

      button {
          margin-right: $base-unit;
          background: black;
          color: $footer-lower-anchor-color;
          border: 1px solid $footer-lower-anchor-color;
          cursor: pointer;
      }

      button:focus {
        outline: none;
      }

      button:active {
        outline: none;
      }
  }
}
</style>
