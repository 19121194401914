var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AudioPlayerBase',{ref:"archivePlayer",staticClass:"archive-player",class:_vm.getIsHiddenPlayer(),scopedSlots:_vm._u([{key:"audio-player-control",fn:function(){return [_c('audio',{ref:"archivesource",attrs:{"id":"archivesource"}}),_c('div',{staticClass:"audio-player-control",class:_vm.archivePlayer.playerState,on:{"click":_vm.playPause}},[_c('span')])]},proxy:true},{key:"audio-player-title",fn:function(){return [_c('router-link',{staticClass:"audio-player-metadata-title",attrs:{"to":{
        name: 'ArchiveDetail',
        params: {
          slug: _vm.archivePlayer.item.show.slug,
          recording: _vm.archivePlayer.item.recording_name
        }
      }}},[_vm._v(" "+_vm._s(_vm.getTitle(_vm.archivePlayer.item))+" ")])]},proxy:true},{key:"audio-player-source",fn:function(){return [_c('span',{staticClass:"time time-elapsed"},[_vm._v(_vm._s(_vm.elapsed))]),_c('input',{ref:"slider",staticClass:"audio-slider",attrs:{"type":"range","min":"0","max":_vm.archivePlayer.item.audio_duration,"step":"1"},on:{"change":_vm.seekItem,"input":_vm.updateSlider}}),_c('span',{staticClass:"time time-remaining"},[_vm._v(_vm._s(_vm.remaining))])]},proxy:true},{key:"audio-player-addon",fn:function(){return [_c('VolumeControl',{attrs:{"audiosource":_vm.getAudioSource(),"sourceid":'archivesource'}}),_c('ToggleControl',{attrs:{"streamplayer":false}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }