<template id="formatted-date-template">
  <span v-if="date" class="formatted-date">
    <span v-if="showMonthShort">{{ dateMonthShort(date) }}</span>
    <span v-if="showMonthShortWhenDiffers && !isActualMonth(date)">{{
      dateMonthShort(date)
    }}</span>
    <span
      >{{ dateDay(date) }}<sup>{{ dateDayOrdinalSuffix(date) }}</sup></span
    >
    <span v-if="showWeekday">{{ dateWeekday(date) }}</span>
    <span v-if="showMonth">{{ dateMonth(date) }}</span>
    <span v-if="showYear">{{ dateYear(date) }}</span>
  </span>
  <span v-else> </span>
</template>

<script>
/* eslint-disable */
import { isMobile, windowWidth } from "@/helpers.js";

export default {
    name: "formatted-date",
    template: "#formatted-date-template",
    props: [
        "date",
        "showMonth",
        "showMonthShortWhenDiffers",
        "showMonthWhenDiffers",
        "showWeekday",
        "showYear", 
        "showMonthShort"
    ],
    data: function() {
        return {
        }
    },
    methods: {
        isActualMonth: function(date) {
            const dt = new Date(date);
            const today = new Date();
            return dt.getMonth() == today.getMonth()
        },
        dateYear: function(date) {
            return new Date(date).toLocaleDateString("en-US", {year: 'numeric'});
        },
        dateMonthShort: function(date) {
            return new Date(date).toLocaleDateString("en-US", {month: 'short'});
        },
        dateMonth: function(date) {
            return new Date(date).toLocaleDateString("en-US", {month: 'long'});
        },
        dateDay: function(date) {
            return new Date(date).getDate();
        },
        dateWeekday: function(date) {
            let format = "long";
            if (this.showMonth && !isMobile() && windowWidth() < 1530) {
                format = "short";
            }
            return new Date(date).toLocaleDateString("en-US", {weekday: format});
        },
        dateDayOrdinalSuffix: function(date) {
            var i = new Date(date).getDate();
            var j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return "st";
            }
            if (j == 2 && k != 12) {
                return "nd";
            }
            if (j == 3 && k != 13) {
                return "rd";
            }
            return "th";
        }
    }
};
</script>

<style lang="scss">
@import "../variables";
.formatted-date {
    span {
        margin-right: $base-unit;
    }

    sup {
        vertical-align: super;
        font-size: .7em;
    }
}
</style>
