<template>
  <div
    v-if="!streamplayer || archivePlayer.item.hls_playlist"
    class="toggle-control"
  >
    <!-- button
      v-if="!streamplayer"
      class="toggle-control-button toggle-playlist"
      :class="toggleControlClass()"
      v-on:click="togglePlaylist"
    >
      <span class="icon-playlist_play"></span>
      <ArchiveQueue v-if="showQueue" />
    </button -->
    <button
      class="toggle-control-button toggle-player"
      :class="toggleControlClass()"
      v-on:click="togglePlayer"
    >
      <template v-if="streamplayer && archivePlayer.item.hls_playlist">
        <span class="icon-history"></span>
      </template>
      <template v-if="!streamplayer">
        <span class="icon-broadcast_fill_icon"></span>
      </template>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isMobile } from "@/helpers.js";
//import ArchiveQueue from "@/components/ArchiveQueue.vue";

export default {
  name: "ToggleControl",
  props: ["streamplayer"],
  components: {
    //    ArchiveQueue
  },
  computed: {
    ...mapState({
      archivePlayer: state => state.archivePlayer,
      streamPlayer: state => state.streamPlayer
    })
  },
  data: function() {
    return {
      showQueue: this.showQueue
    };
  },
  created() {},
  mounted: function() {
    this.showPlaylist = false;
  },
  methods: {
    isMobile,
    toggleControlClass: function() {
      const classes = [];
      if (isMobile()) {
        classes.push("mobile");
      }
      if (!this.streamplayer && this.streamPlayer.playingNow.live) {
        classes.push("on-air-live");
      }
      return classes.join(" ");
    },
    togglePlayer: function() {
      console.log("called togglePlayer() streamplayer is ", this.streamplayer);
      if (this.streamplayer) {
        this.$root.$emit("show_archive_player");
      } else {
        this.$root.$emit("show_stream_player");
      }
    },
    togglePlaylist: function() {
      console.log("called togglePlaylist()", this.showPlaylist);
      this.showQueue = !this.showQueue;
    }
  }
};
</script>

<style lang="scss">
@import "../../variables";
@import "../../mixins";
.toggle-control {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle-control-button,
.toggle-control-button:active {
  outline: none;
  overflow: hidden;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  margin: 0 15px;
  margin-top: 2px;
  padding: 10px;
  &.mobile {
    padding: 10px;
    margin: 0 2px;
  }
}

.on-air-live.toggle-player {
  color: $player-live-broadcast-color;
}
</style>
