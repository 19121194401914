<template id="program-item-template">
  <div
    class="program-item"
    :class="[
      { 'program-closed': closed },
      { 'program-item-loading': shows.isLoading && shows.page === 1 }
    ]"
  >
    <div class="program-item-image-wrapper">
      <div
        class="audio-player-control program-item-play"
        v-bind:class="recent_recording ? stateClass : ''"
        v-on:click="playPause(recent_recording)"
      >
        <span></span>
      </div>

      <img
        v-if="(shows.isLoading && shows.page === 1) || homePage.isLoading"
        v-bind:src="imgPlaceholder"
        class="program-item-image"
      />

      <img
        v-if="!shows.isLoading || shows.page > 1"
        v-bind:src="image"
        class="program-item-image"
        :alt="name"
      />
    </div>
    <router-link
      :to="{ name: 'ShowDetail', params: { slug: slug } }"
      v-slot="{ href, route, navigate }"
    >
      <a :href="href" @click="navigate" class="program-item-name">
        <div>
          <div class="program-title">{{ name }}</div>
          <div
            class="program-artist"
            :class="presenters.length != 0 ? 'available' : ''"
          >
            {{ formatPresenters(presenters) }}
          </div>
        </div>
        <div class="program-item-links">
          <div v-if="tags" class="program-item-tags">
            <span
              v-for="(genre, idx) in tags.slice(0, 3)"
              v-bind:key="idx"
              class="tag"
              :class="{ 'tag--loading': shows.isLoading }"
              >{{ genre.tag }}</span
            >
          </div>
        </div>
      </a>
    </router-link>
  </div>
</template>
<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions } from "vuex";
import GenreLink from "@/components/GenreLink.vue";

export default {
  name: "program-item",
  template: "#program-item-template",
  props: [
    "name",
    "slug",
    "image",
    "presenters",
    "tags",
    "recent_recording",
    "closed",
    "order"
  ],
  components: {
    GenreLink
  },
  data: function() {
    return {
      imgPlaceholder:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcAAAAEBCAQAAABFmL3pAAACHklEQVR42u3TMQ0AAAzDsJU/6b1l0MeGECk5YCYSgAHBgIABwYCAAcGAgAHBgIABwYCAAcGAgAHBgIABwYCAAcGAgAHBgIABwYBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAMKAEYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDggEBA4IBAQOCAQEDggEBA4IBAQOCAQEDggEBA4IBAQOCAQEDggEBA4IBwYASgAHBgIABwYCAAcGAgAHBgIABwYCAAcGAgAHBgIABwYCAAcGAgAHBgIABwYBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCBgQDAgYEAwIGBAMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYEDAgGBAwIBgQMCAYECgPZEkBAjPZ7/YAAAAASUVORK5CYII="
    }
  },
  computed: {
    stateClass: function() {
      var state = ''
      if (this.recent_recording.hls_playlist === this.audioPlayer.src) {
        if (this.archivePlayer.playing) {
          state = 'playing';
        } else if (this.archivePlayer.loading) {
          state = 'waiting';
        }
      }
      return state;
    },
    ...mapState({
      archivePlayer: state => state.archivePlayer,
      audioPlayer: state => state.audioPlayer,
      homePage: state => state.homePage,
      shows: state => state.shows
    })
  },
  mounted: function() {
    this.$root.$on('audioplayer_src_changed', (src) => {
      if (this.recent_recording) {
        if (this.recent_recording.hls_playlist === src && this.archivePlayer.playing) {
          this.playing = true;
        } else {
          this.playing = false;
        }
      }
    });
    this.$root.$on('archiveplayer_play', () => {
      if (this.recent_recording) {
        if (this.recent_recording.hls_playlist === this.audioPlayer.src) {
          this.playing = true;
        }
      }
    });
    this.$root.$on('archiveplayer_pause', () => {
      if (this.recent_recording) {
        if (this.recent_recording.hls_playlist === this.audioPlayer.src) {
          this.playing = false;
        }
      }
    });
    this.$root.$on('archiveplayer_closed', () => {
      this.playing = false;
    });
  },
  methods: {
    playPause: async function(item) {
      var archivePlayer = this.$root.$children[0].$refs.audioPlayer.$refs.archivePlayer;
      if (this.playing) {
        this.playing = false
        archivePlayer.pause();
      } else {
        this.$root.$emit("show_archive_player");
        this.setPlayerActiveState(true);
        this.playing = true;
        if (item.hls_playlist === this.audioPlayer.src || item.hls_playlist === this.archivePlayer.item.hls_playlist) {
          await archivePlayer.play();
        } else {
          //this.setPlayingSrc({src: this.recent_recording.hls_playlist});
          await archivePlayer.loadItem({ item: item });
        }
      }
    },

    formatPresenters: function(presenters) {
      if (!presenters) {
        return '';
      }
      if (presenters.length == 0) {
        return '';
      }
      if (presenters.length > 2) {
        return presenters.map(function(elem) {
          return elem.name;
        }).join(", ");
      }
      return presenters.map(function(elem) {
        return elem.name;
      }).join(" & ");
    },
    ...mapActions("archivePlayer", [
      "setPlayerActiveState"
    ]),
    ...mapMutations("audioPlayer", [
      "setPlayingSrc"
    ])
  }
};
</script>
<style scoped lang="scss">
@import "../variables";

.program-item {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: $base-unit*2;
  margin-bottom: $base-unit*3;
  z-index: 0;
  border-bottom: 1px solid $border-color-default;

  @media (min-width: $screen-sm-min) {
    margin-bottom: $base-unit*5;
    padding-bottom: $base-unit/2;
  }

  .program-item-name {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: $base-unit*2 $base-unit*3 0;
    font-size: 23px;
    font-weight: 300;
    line-height: 31px;
    flex: 1;
    text-decoration: none;

    @media (min-width: $screen-sm-min) {
      font-size: 19px;
      padding: $base-unit 0 0;
      line-height: 1.618;
    }

    &:hover {
      cursor: pointer;

      .program-title {
        text-decoration: underline;
      }

    }

    .program-title {
      padding-right: $base-unit;
      margin-bottom: $base-unit;
      color: $color-secondary;
      font-family: $title-font;
      font-weight: normal;
      font-size: 24px;
      line-height: 25px;
      text-transform: uppercase;
    }

    .program-artist {
      margin-bottom: $base-unit;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.8;
      color: $txt-color;

      @media (min-width: $screen-sm-min) {
        line-height: 1.2;
      }
    }
    .program-artist.available {
      &:before {
        content: "by ";
        font-weight: 400;
      }
    }
  }

  &.program-closed {
    .program-artist {
      &:before {
        content: "";
      }
    }
  }

  .program-item-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: $base-unit/2;
    line-height: 1.618;
    font-size: 14px;
  }

  .program-item-image-wrapper {
    position: relative;
    margin-bottom: $base-unit;
  }

  .program-item-image {
    display: block;
    width: 100%;
    height: auto;
  }

  .program-item-play {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -3.5rem;
    margin-left: -3.5rem;
    padding: 0;
    font-size: 7rem;
    line-height: 1;

    @media (max-width: $screen-sm-min) {
      display: block;
    }
  }

  .program-item-play.playing,
  .program-item-play.waiting {
    display: block;
  }


  &.program-item-loading {
    letter-spacing: -.3px;

    .program-title {
      color: $txt-color;
      opacity: .7;
    }

    .program-artist {
      opacity: .7;
      &:before {
        content: "";
      }
    }

    .program-item-image-wrapper:hover .program-item-play {
      display: none
    }
  }
}

.overlay-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 25%;
  left: 37%;
}

.program-item-image-wrapper:hover .program-item-play {
  display: block;
}
</style>
