var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AudioPlayerBase',{ref:"streamPlayer",staticClass:"stream-player",scopedSlots:_vm._u([{key:"audio-player-control",fn:function(){return [_c('audio',{ref:"streamsource",attrs:{"id":"source","src":_vm.streamUrl,"preload":"none"}}),_c('div',{ref:"streamplayer",staticClass:"audio-player-control",class:_vm.streamPlayer.playerState,attrs:{"id":"streamplayer"},on:{"click":_vm.playPause}},[_c('span')])]},proxy:true},{key:"audio-player-source",fn:function(){return [(_vm.streamPlayer.playingNow.live)?_c('div',{staticClass:"playing-now-live"},[_c('span',{staticClass:"on-air-dot"}),_c('small',{staticClass:"live-broadcast"},[_vm._v(" On Air ")])]):_c('div',{staticClass:"playing-now-live"},[_c('span',{staticClass:"now"},[_vm._v("Now playing")])])]},proxy:true},{key:"audio-player-title",fn:function(){return [(_vm.streamPlayer.playingNow.recording_name)?[_c('router-link',{staticClass:"stream-player-metadata-title",attrs:{"to":{
          name: 'ArchiveDetail',
          params: {
            slug: _vm.streamPlayer.playingNow.show.slug,
            recording: _vm.streamPlayer.playingNow.recording_name
          }
        }}},[_vm._v(" "+_vm._s(_vm.nowPlaying)+" ")])]:[_vm._v(" "+_vm._s(_vm.nowPlaying)+" ")]]},proxy:true},{key:"audio-player-addon",fn:function(){return [_c('VolumeControl',{attrs:{"audiosource":_vm.getAudioSource(),"sourceid":'source'}}),_c('ToggleControl',{attrs:{"streamplayer":true}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }